import { useEffect, useState } from 'react';
import { authGet, authPost } from './Utility';
import { useNavigate } from 'react-router-dom';
import './DoctorRecord.css'
import InputOpParams from './InputOpParams';

const timeDiff = (start, end)=>{
    const totalSec = (end - start) / 1000;
    const min = Math.round(totalSec / 60) % 60;
    const hour = Math.round(totalSec / 60 / 60);

    return (hour > 0 && `${hour} 시간 ` || '') + `${min} 분`;
}


function PopupDialog({children, visible, onClose, onOK}) {
    const popupStyle= {
        position:"fixed",
        top:120,
        left:0,
        right:0,
        bottom:0,
        display: visible ? "block" : "none",
        padding: "25px",
        backgroundColor: "rgba(30,30,30,0.5)",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflowY:"auto"
    }

    return (
      <div style={popupStyle}>
        {children}
      </div>
    )
}

async function getMyOperations(callback){
    return authGet('/api/myoperation').then(res=>callback(res.data));
}

async function getArchOpParams(fileKey, callback){
    return authGet(`/api/getopparam/${fileKey}`).then(res=>callback(res.data));
}

async function updateArchOpParam(param, callback){
    return authPost('/api/regopparam',param).then(callback);
}

const nav_analysis = (navigate, o)=>{
    navigate(`/analysis?fkey=${encodeURIComponent(o.fileKey)}&fname=${encodeURIComponent(o.filePath)}&stime=${o.opStartTime}&etime=${o.opEndTime}`);
}

export default function DoctorRecord(){
    const navigate = useNavigate();
    const [operations, setOperation] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [opExtraData, setOpExtraData] = useState(undefined);
    const [selectedOpInfo, setOpInfo] = useState(undefined);
    
    const opParamDialog = {
        setVisible: (visible) => {
            setDialogVisible(visible)
        },
        openDialog: (opInfo) => {
            setOpInfo(opInfo);

            getArchOpParams(opInfo.fileKey, (archOpParams)=>{
                const myParam = archOpParams.filter(v=>v.opStartTime == opInfo.opStartTime);
                if(myParam.length > 0){
                    setOpExtraData(JSON.parse(myParam[0].opExtraData));
                } else {
                    setOpExtraData(undefined);
                }
                opParamDialog.setVisible(true)
            });
            
        },
        closeDialog: () => {opParamDialog.setVisible(false);},
        handleDialogOK: (data) => {
            const o = selectedOpInfo;
            const param = {
                fkey:o.fileKey, 
                finfo: {
                    filePath:o.filePath,
                    startTime:o.opStartTime,
                    endTime:o.opEndTime
                }, opparam: data
            }

            updateArchOpParam(param, ()=>{
                opParamDialog.closeDialog()
            })
            
        }
    }

    useEffect(()=>{
        getMyOperations(data=>{setOperation(data)})
    },[]);
    

    const handleInputOpParam = (o)=>{
        opParamDialog.openDialog(o);
    }

    return (
    <article>
        <header>
            <h2>Doctor Record page</h2>
        </header>
        <table>
            <thead>
                <tr>
                    <td>시작 시간</td>
                    <td>수술 시간</td>
                    <td>Action</td>
                    <td>Archive Key</td>
                </tr>
            </thead>
            <tbody>
                {operations.map(o=>(
                <tr>
                    <td>{new Date(o.opStartTime).toLocaleString()}</td>
                    <td>{timeDiff(o.opStartTime,o.opEndTime)}</td>
                    <td>
                        <button onClick={()=>{nav_analysis(navigate, o)}}>보기</button>
                        <button onClick={()=>{handleInputOpParam(o)}} aria-busy={false} disabled={false} > 수술 정보 입력</button>&nbsp;
                    </td>
                    <td><abbr title={o.fileKey}>{o.fileKey.substr(0,6)}</abbr></td>
                </tr>))}

            </tbody>
        </table>
        
        
        <PopupDialog visible={dialogVisible}>
            <InputOpParams opExtraData={opExtraData} onClose={opParamDialog.closeDialog} onOK={opParamDialog.handleDialogOK}/>
        </PopupDialog>
        
    </article>)
}