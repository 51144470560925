import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";

import axios from "axios";
import moment from "moment";

import LogTimeline from "./LogTimeline";


const table = {
    'HardStone':{
        'Fine Dusting':{
            'TFL':{
                '150':35.68,
                '272':45.93
            },
            'Ho':{
                '272':null
            }
        },
        'Dusting':{
            'TFL':{
                '150':38.38,
                '272':61.1
            },
            'Ho':{
                '272':16.26
            }
        },
        'Fragmentation':{
            'TFL':{
                '150':41.21,
                '272':66.96
            },
            'Ho':{
                '272':31.74
            }
        }
    },
    'SoftStone':{
        'FineDusting':{
            'TFL':{
                '150':37.77,
                '272':44.88
            },
            'Ho':{
                '272':null
            }
        },
        'Dusting':{
            'TFL':{
                '150':41.12,
                '272':62.88
            },
            'Ho':{
                '272':22.99
            }
        },
        'Fragmentation':{
            'TFL':{
                '150':50.16,
                '272':66.57
            },
            'Ho':{
                '272':38.6
            }
        },
    }
}

function LasingEfficacyAnalysis({data, laserTimeMinutes=0})
{
    //console.log('LasingEfficacyAnalysis data ========',data);

    const valueCheck = v=> (isNaN(v) || !isFinite(v)) ? 0 : v;

    const DTE = valueCheck(data.laser.power * data.laser.frequency * laserTimeMinutes);
    const efficiency = valueCheck(DTE/data.stone.volume);
    const efficacy = valueCheck(data.stone.volume / laserTimeMinutes);
    const stoneType = data.stone.density <1000 ? 'SoftStone' : 'HardStone';

    const tableValue = table[stoneType][data.laser.opType][data.laser.type][data.laser.wavelength];
    const ExDL= data.stone.volume / tableValue 

    if(isNaN(efficacy) || !isFinite(efficacy)){
        efficacy = 0;
    }

    return(
    <table>
        <thead>
            <tr>
                <th>name</th>
                <th>value</th>
                <th>description</th>
            </tr>
        </thead>

        <tr>
            <td>Expected lasing time</td>
            <td><strong>{ExDL.toFixed(3)}</strong> (min)</td>
            <td>예상 레이저 조사 시간</td>
        </tr>
        <tr>
            <td>Actual lasing time</td>
            <td><strong>{laserTimeMinutes.toFixed(3)}</strong> (min)</td>
            <td>실제 레이저 조사 시간</td>
        </tr>

        <tr>
            <td>Delivered total energy</td>
            <td><strong>{DTE.toFixed(3)}</strong> (W)</td>
            <td>결석 제거에 사용된 총 에너지</td>
        </tr>
        
        <tr>
            <td>Efficacy</td>
            <td><strong>{efficacy.toFixed(3)}</strong> (㎣/min)</td>
            <td>분당 제거된 결석 부피</td>
        </tr>

        <tr>
            <td>Efficiency</td>
            <td><strong>{efficiency.toFixed(3)}</strong> (J/㎣)</td>
            <td>부피당 결석 제거에 사용된 에너지</td>
        </tr>



    </table>
    )
}

function PopupResult({visible, onClose, data, opParams}) {
    //const [graphType,setGraphType]=useState(0);
    //useEffect(()=>{console.log('PopupResult data changed',data?.length)},[data]);
    const [laserTime, setLaserTime] = useState(0);
    const [opTime, setOpTime] = useState(0);


    if(!data)
        return (<div></div>)

    const popupStyle= {
        position:"fixed",
        top:120,
        left:0,
        right:0,
        bottom:0,
        display: visible ? "block" : "none",
        padding: "25px",
        backgroundColor: "rgba(30,30,30,0.5)",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflowY:"auto"
    }

    const articleStyle = {
        maxHeight:"500px",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflow:"auto"
    }


    return (
      <div style={popupStyle}>
        <article sytle={articleStyle}>
          <header>
            <p><strong>로그 분석 / 수술 시간 {Math.round(opTime)} (분)</strong></p>
          </header>
          <main>
            <LogTimeline logData={data} setLaserTime={setLaserTime} setOpTime={setOpTime}/>
            { opParams && (
                <div>
                    <details open>
                        <summary role="button" class="secondary">통계 데이터</summary>
                        <LasingEfficacyAnalysis data={JSON.parse(opParams.opExtraData)} laserTimeMinutes={laserTime} />
                    </details>
                    <details open>
                        <summary role="button" class="outline secondary">수술 입력 데이터 원본</summary>
                        <pre>{JSON.stringify(JSON.parse(opParams.opExtraData),null,2)}</pre>
                    </details>
                </div>
            ) || (<p>통계 입력 데이터가 존재하지 않음</p>)}
          </main>
        </article>
      </div>
    )
}

// 비동기 logToJSON 함수
async function logToJSONAsync(logContent, timeRange, onProgress = (cur, total) => {}) {
    const lines = logContent.split("\r").filter((line) => line.trim() !== "");
    const headers = lines[0].split("\t")[5].split(",");
    const total = lines.length - 1;
    const parsedData = [];
  
    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        const parts = line.split("\t");
        if (parts.length < 6) continue;

        if (i % 1000 === 0) {
            onProgress(i, total);
            await new Promise((resolve) => setTimeout(resolve, 0)); // 비동기 업데이트
        }

        const timestamp = moment(`${parts[1]} ${parts[2]}`, "YYYY.MM.DD HH:mm:ss.SSS");
        const entry = {
            id: parts[0],
            datetime: timestamp.toISOString(),
            momenttime: timestamp,
            controller: parts[3],
            type: parts[4],
        };

        const values = parts[5].split(",");
        headers.forEach((header, index) => {
            entry[header] = isNaN(values[index]) ? values[index] : parseFloat(values[index]) || 0;
        });

        parsedData.push(entry);
    }

    onProgress(total, total);

    // 타임레인지 필터링
    if (timeRange) {
        return parsedData.filter((entry) => entry.momenttime.isBetween(timeRange[0], timeRange[1], undefined, "[]"));
    }
    return parsedData;
}

export default function Analysis(){

    const [sparams] = useSearchParams();
    const fkey = sparams.get('fkey');
    const fname = sparams.get('fname');
    const stime = sparams.get('stime');
    const etime = sparams.get('etime');
    const hasRange = stime != null && etime != null;
    const trange = hasRange && ([moment(parseInt(stime)),moment(parseInt(etime))]) || null ;


    const [progress, setProgress] = useState('Loading...');
    const [logContent, setLogContent] = useState(null);
    const [opParams, setOpParams] = useState(null);
    //const [fkey, setfkey] = useState(sparams.get('fkey'));
    //const {fkey, fname} = useParams();
    
    
    const fetchOpParams = ()=>{
        if(!fkey)
            return;
        
        const config = { headers: { 'access-token': localStorage.getItem('access-token') } }

        axios.get(`/api/getopparam/${fkey}`, config ).then((response) => {
            const operations = response.data;
            const filteredOp = operations.filter(v=>v.opStartTime == stime);
            
            if(filteredOp.length > 0){
                console.log('getOpParams Result: ', filteredOp);
                setOpParams(filteredOp[0]);
            } else {
                //console.error('no opparam found');
            }
            
        })
        .catch((error) => {
            console.error('error:', error);
        });
    }
    //useEffect(fetchOpParams,[])
    


    const a = ()=>{
        
        let downloadCompleted = false;
        const config = {
            responseType: 'blob' ,
            headers: { 
                'access-token': localStorage.getItem('access-token')
            },
            onDownloadProgress: (progressEvent) =>{
                const totalLength = progressEvent.lengthComputable 
                        ? progressEvent.total
                        : parseInt(progressEvent.target.getResponseHeader('content-length'), 10);
                    if (totalLength && !downloadCompleted) {
                        let percent = Math.round((progressEvent.loaded * 100) / totalLength)
                        setProgress(`Download: ${percent} %`);
                    }
            }
        }

        axios.post(`/api/reqkey?key=${encodeURIComponent(fkey)}&filename=${encodeURIComponent(fname)}`, {}, config ) // 파일을 Blob으로 받음
        .then((response) => {
            // console.log('download completed: ', fname);
            downloadCompleted = true;
            setProgress("Download: 100 %");

            const file = new Blob([response.data]);

            const reader = new FileReader();
                reader.onload = async (e) => {

                    const parseLogProgress = (cur,total) => {setProgress(`Parsing: ${Math.round(cur*1000/total)/10} %`)}
                    const data = e.target.result;

                    //const stime = moment();
                    const jsonData = await logToJSONAsync(data, trange,parseLogProgress);
                    //const etime = moment();
                    //console.log(`Execution time (logToJSON): ${moment.duration(etime.diff(stime)).asMilliseconds()} ms`);
                    setLogContent(jsonData);
                };
                reader.readAsText(file);

        })
        .catch((error) => {
          console.error('File download error:', error);
        });
    }

    useEffect(()=>{
        fetchOpParams();
        a();
    },[]);

    return (
    <div>
        {progress}
        <PopupResult visible={logContent !== null} data={logContent} opParams={opParams} onClose={()=>{setLogContent(null)}}/>
    </div>
    )
}