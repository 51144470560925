import axios from "axios";

export async function authGet(url) {
    const config = { headers: { 'access-token': localStorage.getItem('access-token') } }
    return axios.get(url,config).catch(error=>{console.error('err on authGet', error);});
}

export async function authPost(url,data={}) {
    const config = { headers: { 'access-token': localStorage.getItem('access-token') } }
    return axios.post(url,data,config).catch(error=>{console.error('err on authPost', error);});
}