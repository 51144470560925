import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputOpParams from './InputOpParams';
import { authGet, authPost } from './Utility';

function formatFileSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2)); // 소수점 2자리까지 표시
    
    return `${size} ${sizes[i]}`;
}

const nav_analysis = (navigate, fkey,i)=>{
    navigate(`/analysis?fkey=${encodeURIComponent(fkey)}&fname=${encodeURIComponent(i.filePath)}&stime=${i.startTime}&etime=${i.endTime}`);
}

async function archViewOp(fkey, callback){
    return authGet(`/api/archviewop/${fkey}`).then(res=>callback(res.data));
}

async function fetchDoctorList(callback){
    return authGet('/api/admin/doctorlist').then(res=>callback(res.data));
}

async function getArchiveConnections(fileKey, callback){
    return authGet(`/api/admin/getdoctorconnect/${fileKey}`).then(res=>callback(res.data));
}

// param: {doctorIdx, fileKey, connect: [{opStartTime, opEndTime}]}
async function updateArchiveConnections(param, callback){
    return authPost('/api/admin/doctorconnect',param).then(callback);
}

async function getArchOpParams(fileKey, callback){
    return authGet(`/api/getopparam/${fileKey}`).then(res=>callback(res.data));
}

// param: {fkey, finfo, opparam}
async function updateArchOpParam(param, callback){
    return authPost('/api/regopparam',param).then(callback);
}


function UserList({fkey, users, onUserChange=()=>{}, onDoctorConnect=(idx)=>{}})
{
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserName, setSelectedUserName] = useState('');
    const [confirmDialog, setConfirmDialogVisible] = useState(false);

    const [doctorList, setDoctorList] = useState([]);
    const [selectedDoctorIdx, setSelectedDoctor] = useState('');
    
    const changeEvent = (event)=>{
        const value = event.target.value;

        const findRes = users.find(u=>u.name === value);

        onUserChange(value);
        setSelectedUserName(value);
        setSelectedUser(findRes);
    }

    const handleMatchAccount = ()=>{
        setConfirmDialogVisible(true);

        fetchDoctorList(data=>{setDoctorList(data);});

        console.log('selected user',selectedUser);
        console.log('account matching data:', {
            fkey,
            arch_userid: selectedUser.id,
            arch_name: selectedUser.name,
        })
    }

    const handleDoctorConnectCancel = ()=>{
        setConfirmDialogVisible(false);
        setSelectedDoctor('');
    }
    const handleDoctorConnectConfirm = ()=>{
        setConfirmDialogVisible(false);
        
        onDoctorConnect(selectedDoctorIdx)
    }

    return users.length > 0 && (
        <div>
            <select onChange={changeEvent} value={selectedUserName}>
                <option value=""> -- 계정을 선택하세요 -- </option>
                { users && users.map(u=>(
                    <option value={u.name}>{u.name} ({u.fileCount})</option>
                ))}
            </select>
            <button onClick={handleMatchAccount} disabled={!selectedUser}>선택한 계정의 모든 데이터를 연결</button>
            <dialog open={confirmDialog}>
                <article>
                    <h2>연결할 의사 계정을 선택하세요.</h2>

                    <select onChange={e=>setSelectedDoctor(e.target.value)} value={selectedDoctorIdx}>
                        <option disabled selected value="">-- 선택하세요 --</option>
                        {doctorList.map(i=>(<option value={i.idx}>{i.name} ({i.userid})</option>))}
                    </select>
                    <footer>
                        <button onClick={handleDoctorConnectCancel} class="secondary">Cancel</button>
                        <button onClick={handleDoctorConnectConfirm} disabled={selectedDoctorIdx === ''} >Confirm</button>
                    </footer>
                </article>
            </dialog>
        </div>
    )
}

function DisplaySelectedUserData({data, fkey})
{
    const navigate = useNavigate();
    const itemsPerPage = 10; // 한 페이지에 표시할 항목 수
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
    const [isOpParamLoading, setOpParamLoading] = useState(true);
    const [opParams, setOpParams] = useState([]);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedOp, setSelectedOp] = useState(false);
    const [selectedOpExtraData, setSelectedOpExtraData] = useState(null);

    const [connectDialog, openConnectDialog] = useState(false);
    const [connectDialogSelectedOp, setConnectDialogSelectedOp] = useState(null);
    const [connectDialogSelectedIdx, setConnectDialogSelectedIdx] = useState('');
    const [doctorConnect, setDoctorConnect] = useState([]);
    const [doctorConnectLoading, setDoctorConnectLoading] = useState(true);
    const [doctorList, setDoctorList] = useState([]);
    
    const openDialog = ()=>{setDialogVisible(true)}
    const closeDialog = ()=>{setDialogVisible(false)}

    // info: {filepath, starttime, endtime}
    const handleInputOpParam = (fkey, info)=>{
        setSelectedOp(info);
        
        if(info.opParams.length > 0)
            setSelectedOpExtraData(JSON.parse(info.opParams[0].opExtraData));
        else
            setSelectedOpExtraData(null);

        openDialog();
    }

    const handleDialogOK = (data)=>{
        updateArchOpParam({fkey, finfo: selectedOp, opparam: data}).then(()=>{
            closeDialog();
            startGetOpParams();
        })
    }

    const getOpParams = ()=>{
        if(!fkey) return;
        
        getArchOpParams(fkey, data => { // data: [{fkey, finfo:{filepath, starttime, endtime}, opparam}]
            setOpParams(data);
            setOpParamLoading(false);
        })
    }

    const getDoctorConnect = ()=>{
        if(!fkey) return;

        getArchiveConnections(fkey, data => { // data: [{doctorIdx, fileKey, connect: [{opStartTime, opEndTime}]}]
            setDoctorConnect(data);
            setDoctorConnectLoading(false);
        })
    }

    const startGetOpParams = ()=>{
        setOpParamLoading(true);
        setTimeout(getOpParams, 1000);
    }

    const startGetDoctorConnect = ()=>{
        setDoctorConnectLoading(true);
        setTimeout(getDoctorConnect, 1000);
    }


    useEffect(()=>{
        fetchDoctorList(data => { // data: [{idx, name, userid}]
            setDoctorList(data);
        });
    },[])

    const handleConnectButton = (opInfo)=>{
        openConnectDialog(true);
        setConnectDialogSelectedOp(opInfo);
    }

    const handleConnectDialogCancel = ()=>{
        openConnectDialog(false);
        setConnectDialogSelectedIdx('');
    }
    const handleConnectDialogConfirm = ()=>{
        openConnectDialog(false);
        
        const param = {
            doctorIdx: connectDialogSelectedIdx,
            fileKey: fkey,
            connect: [connectDialogSelectedOp]
        };

        updateArchiveConnections(param, res=>{console.log(res.status);});
    }


    useEffect(()=>{ setCurrentPage(1) }, [data])
    useEffect(startGetOpParams, [fkey])
    useEffect(startGetDoctorConnect, [fkey])


    const timeDiff = (start, end)=>{
        const totalSec = (end - start) / 1000;
        const min = Math.round(totalSec / 60) % 60;
        const hour = Math.round(totalSec / 60 / 60);

        return (hour > 0 && `${hour} 시간 ` || '') + `${min} 분`;
    }

    if(!data)
        return (<div><p>계정을 선택해 주세요.</p></div>);


    const totalPages = Math.ceil(data.info.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = data.info.slice(startIndex, startIndex + itemsPerPage);

    const displayItems = currentItems.map((i, index)=>({
        key:            index,
        startTime:   i.starttime,
        endTime:     i.endtime,
        startTimeStr:   new Date(parseInt(i.starttime)).toLocaleString(),
        endTimeStr:     new Date(parseInt(i.endtime)).toLocaleString(),
        opDuration:     timeDiff(parseInt(i.starttime),parseInt(i.endtime)),
        filePath:       i.filepath,
        logFileSize:    formatFileSize(i.filesize),
        opParams:       opParams.filter(v=>v.opStartTime == i.starttime),
        connectedDoctorName:    doctorConnect.filter(v=>v.opStartTime == i.starttime).map(v=>v.name)[0],
        hasOpParams:            function() { return this.opParams.length > 0 },
        hasDoctorConnected:     function() { return this.connectedDoctorName.length > 0},
        orgInfo:i,
        
        //hasOpParams:    item.opParams.length > 0 ? true : false,
    }))

    return(
    <div style={{overflowY:"auto"}}>
        {/* 페이지네이션 UI */}
        <div>
            <hr/>
            <button className="secondary" onClick={handlePreviousPage} disabled={currentPage === 1}>이전 페이지</button>
            <span style={{ margin: "0 10px" }}>{currentPage} / {totalPages}</span>
            <button className="secondary" onClick={handleNextPage} disabled={currentPage === totalPages}>다음 페이지</button>
            <hr/>
        </div>
        <table>
            <thead>
                <tr>
                    <th>수술 시작/종료</th>
                    <th>수술 시간</th>
                    <th>기록 크기</th>
                    <th>연결된 의사 계정</th>
                    <th>--</th>
                </tr>
            </thead>
            <tbody> 
                { 
                displayItems.map((i,index) => (
                    <tr key={index}>
                        <td>{i.startTimeStr}</td>
                        <td style={{textAlign:'right'}}><abbr title={i.endTimeStr}>{i.opDuration}</abbr></td>
                        <td style={{textAlign:'right'}}><span data-tooltip={i.filePath}>{i.logFileSize}</span></td>
                        <td>
                            <div aria-busy={doctorConnectLoading}>
                                {!doctorConnectLoading && (i.connectedDoctorName || (<span style={{color:'gray'}}>[연결되지 않음]</span>))}
                            </div>
                            <div role="group"  >
                                <button className="secondary" onClick={()=>{handleConnectButton(i.orgInfo);}} aria-busy={doctorConnectLoading} disabled={doctorConnectLoading} >연결</button>
                                <button className="secondary" onClick={()=>{}} aria-busy={doctorConnectLoading} disabled={doctorConnectLoading || i.connectedDoctorName ? false : true} >연결 해제</button>
                            </div>
                            
                        </td>
                        <td>
                            <button onClick={()=>{handleInputOpParam(fkey,i)}} aria-busy={isOpParamLoading} disabled={isOpParamLoading} class={i.hasOpParams() ? "secondary":"primary"}> 수술 정보 {(i.hasOpParams() ? "수정":"입력")}</button>&nbsp;
                            <button onClick={()=>{nav_analysis(navigate, fkey,i)}}>기록 보기</button>
                        </td>
                    </tr>
                    ))
                }
            </tbody>
        </table>

        <PopupDialog visible={dialogVisible}>
            <InputOpParams opExtraData={selectedOpExtraData} onClose={closeDialog} onOK={handleDialogOK}/>
        </PopupDialog>

        <dialog open={connectDialog}>
            <article>
                <h2>연결할 의사 계정을 선택하세요.</h2>

                <select onChange={e=>setConnectDialogSelectedIdx(e.target.value)} value={connectDialogSelectedIdx}>
                    <option disabled selected value="">-- 선택하세요 --</option>
                    {doctorList.map(i=>(<option value={i.idx}>{i.name} ({i.userid})</option>))}
                </select>
                <footer>
                    <button onClick={handleConnectDialogCancel} class="secondary">Cancel</button>
                    <button onClick={handleConnectDialogConfirm} disabled={connectDialogSelectedIdx === ''} >Confirm</button>
                </footer>
            </article>
        </dialog>
    </div>
    )
}

function PopupDialog({children, visible}) {
    const popupStyle= {
        position:"fixed",
        top:120,
        left:0,
        right:0,
        bottom:0,
        display: visible ? "block" : "none",
        padding: "25px",
        backgroundColor: "rgba(30,30,30,0.5)",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflowY:"auto"
    }

    return (
      <div style={popupStyle}>
        {children}
      </div>
    )
}

export default function ArchViewByOp(/*props*/) {
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState(''); 
    const [fileList, setFileList] = useState(null);  // 다운로드 받은 파일 리스트
    const [userList,setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserData, setSelectedUserData] = useState(null);


    const {fkey} = useParams();

    const getLogArchive = ()=>{
        if(!fkey)
            return;

        archViewOp(fkey, (data)=>{
            setProgress(100);
            setMessage(data.message);
            setFileList(data.data);
        })
    }

    useEffect(getLogArchive, [fkey]);

    const updateData = ()=>{
        if(!fileList)
            return;

        let names = [];

        // 파일 목록을 순회하며 매핑 정보를 추출
        fileList.forEach(f => {
            f.mapping.forEach(m => {
                const findres = names.find(v=> v.name === m.name);

                const i = {
                    filepath: m.filepath, 
                    filesize: f.size,
                    starttime: m.starttime,
                    endtime: m.endtime,
                }

                if(findres) {
                    findres.fileCount++;
                    findres.info.push(i)
                } else {
                    names.push({name:m.name, id: m.id, fileCount:1, info:[i] });
                }
            })
        })
        // 추출한 매핑 정보를 기반으로 사용자 목록을 생성
        setUserList(names);
    }
    useEffect(updateData,[fileList]);
    useEffect(()=>{setSelectedUserData(userList.find(v=>v.name === selectedUser));},[selectedUser])

    const handleDoctorConnectAll = (idx)=>{
        // 선택한 의사 계정으로 모든 수술 기록을 연결
        const param = {
            doctorIdx: idx,
            fileKey: fkey,
            connect: selectedUserData.info
        };

        updateArchiveConnections(param, res=>{console.log(res.status);});
    }
    

    return (
        <div> 
            <progress value={progress} max="100"></progress>
            <p>{progress}% - {message}</p>
            <hr/>
            <UserList fkey={fkey} users={userList} onUserChange={setSelectedUser} onDoctorConnect={handleDoctorConnectAll}/>
            
            {selectedUser && (
                <DisplaySelectedUserData data={selectedUserData} fkey={fkey}/>
            )}

        </div>
    );
};